export default function heroBgAnimate() {

	const sectionHero = document.getElementById('hero');

	if (!sectionHero) {

		return;

	}

	const sectionHeroBg = sectionHero.querySelector('.hero-bg');

	sectionHeroBg.classList.add('animate');

}