export default function inviewDetect() {

	const gridItems = document.querySelectorAll('.grid-item');

	const config = { threshold: [0.2] };

	const observer = new IntersectionObserver(function(entries) {

		entries.forEach(function(entry) {

			if (entry.intersectionRatio > 0) {

				entry.target.classList.add('inview');

				observer.unobserve(entry.target);

			}

		});

	}, config);

	gridItems.forEach((titles) => observer.observe(titles));

}