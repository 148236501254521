import lazySizes from 'lazysizes';
lazySizes.cfg.lazyClass = 'lazy';

// import header from './components/header.js';
import navigation from './components/navigation.js';
import inviewDetect from './components/inview-detect.js';
import heroBgAnimate from './components/hero-bg-animate.js';
import modals from './components/modals.js';
import facebookTrackingPixel from './components/facebook-tracking-pixel.js';

/*-------------------------------------------*/
/* GLOBAL: BACK-FORWARD CACHE ISSUE
// stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
/*-------------------------------------------*/
// window.onpageshow = function(e) {
//
// 	const html = document.querySelector('html');
// 	const body = document.querySelector('body');
// 
// 	if (e.persisted) {
//
// 		html.classList.remove('page-exiting');
// 		body.classList.remove('page-exiting');
//
// 	}
//
// };

window.addEventListener('DOMContentLoaded', navigation, false);
window.addEventListener('DOMContentLoaded', inviewDetect, false);
window.addEventListener('load', heroBgAnimate, false);
window.addEventListener('DOMContentLoaded', modals, false);
window.addEventListener('DOMContentLoaded', facebookTrackingPixel, false);

/*-------------------------------------------*/
/* GLOBAL: WEB DEVELOPER CREDITS
/*-------------------------------------------*/
window.onload = console.log('%c Critical Music Wordpress Theme (2.1.3) \n Wordpress Development: Rich Kelly [https://richkelly.uk]','background: black; color: white; text-transform: uppercase; font-size: 15px;');
