import modalShare from './modal-share.js';

export default function modals() {

	const html = document.querySelector('html');
	const body = document.querySelector('body');
	const header = document.querySelector('header');
	const modalContainer = document.querySelector('.modal-container');

	const toggleOverlay = (e) => {

		html.classList.toggle('overlay-visible');
		body.classList.toggle('overlay-visible');

	}

	const findModal = (e) => {

		const modalId = e.target.closest('[data-modal-id]').dataset.modalId;
		const modal = modalContainer.querySelector('[data-modal-id="' + modalId + '"]');

		return modal;

	}

	const toggleModal = (e) => {

		e.preventDefault();

		let modal = modalContainer.querySelector('.modal--active');

		html.classList.toggle('modal-active');

		// toggleOverlay();

		if (html.classList.contains('modal-active')) {

			modalContainer.style.display = 'grid';
			modalContainer.setAttribute('aria-hidden', true);

			modal = findModal(e);

			setTimeout(() => {

				modalContainer.classList.add('modal-container--active');
				modalContainer.setAttribute('aria-hidden', false);

				modal.style.display = 'grid';

				modal.setAttribute('aria-hidden', false);
				modal.setAttribute('aria-expanded', true);

			}, 5);

			setTimeout(() => modal.classList.add('modal--active'), 10);

		} else {

			modal.classList.remove('modal--active');
			modal.setAttribute('aria-hidden', true);
			modal.setAttribute('aria-expanded', false);

			modalContainer.classList.remove('modal-container--active');
			modalContainer.setAttribute('aria-hidden', true);

			setTimeout(() => {

				modal.style.display = 'none';
				modalContainer.style.display = 'none';

			}, 200);

		}

	}

	const closeNavPanel = (e) => {

		html.classList.remove('nav-open');
		header.classList.remove('nav-open');

		toggleModal(e);

	}

	const clickEvent = (e) => {

		switch (true) {

			case e.target.matches('a.modal-trigger') && html.classList.contains('nav-open'):
			case e.target.parentElement.matches('a.modal-trigger') && html.classList.contains('nav-open'):

				closeNavPanel(e);

			break;

			case e.target.matches('a.modal-trigger'):
			case e.target.parentElement.matches('a.modal-trigger'):
			case e.target.matches('a.modal-close'):
			case e.target.parentElement.matches('a.modal-close'):
			case e.target.matches('.modal-container--active'):

				toggleModal(e);

				toggleOverlay();

			break;

		}

	}

	document.addEventListener('click', clickEvent, false);

}

window.addEventListener('DOMContentLoaded', modalShare, false);
