export default function modalShare() {

	const pageUrl = document.URL;
	const pageTitle = document.title;
	const encodedUrl = encodeURIComponent(pageUrl);
	const encodedTitle = encodeURIComponent(pageTitle);
	const modal = document.querySelector('.modal[data-modal-id="share-modal"]');


	// Stick the page URL into this input field...
	modal.querySelector('input#url').value = pageUrl;

	// Page URL select...
	modal.querySelector('input#url').addEventListener('click', (e) => {

		e.target.select();

	}, false);


	modal.addEventListener('click', (e) => {

		const clicked = e.target;

		if (clicked.closest('a.facebook')) {

			window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl, 'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=250');

		}

		if (clicked.closest('a.twitter')) {

			window.open('https://twitter.com/intent/tweet?text=' + encodedTitle + '&url=' + encodedUrl, 'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=250');

		}

		if (clicked.closest('a.tumblr')) {

			window.open('https://www.tumblr.com/widgets/share/tool?canonicalUrl='+ encodedUrl + '&title=' + encodedTitle, 'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=250');

		}

		if (clicked.closest('a.reddit')) {

			window.open('http://www.reddit.com/submit?url='+ encodedUrl + '&title=' + encodedTitle, 'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=250');

		}

		if (clicked.closest('a.whatsapp')) {

			window.open('whatsapp://send?text=' + encodedUrl,'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=250');

		}

		if (clicked.closest('a.messenger')) {

			window.open('fb-messenger://share?link=' + encodedUrl);

		}

		if (clicked.closest('a.mail')) {

			window.location.href = "mailto:?subject=" + document.title + "&body=" + window.location.href;

		}

	}, false);


}
