/*-------------------------------------------*/
/* GLOBAL: FACEBOOK TRACKING PIXEL - CUSTOM TRACKING
// developers.facebook.com/docs/facebook-pixel/events-advanced-use-cases/v2.10
/*-------------------------------------------*/
export default function facebookTrackingPixel() {

	const clickEvent = (e) => {

		if (!e.target.closest('.icon-link')) {

			return;

		}

		const iconLink = e.target.closest('.icon-link');

		if (iconLink.matches('[href*="itunes.apple.com"]')) {

			fbq('trackCustom', 'preferences', { platform: 'iTunes', });

		}

		if (iconLink.matches('[href*="beatport.com"]')) {

			fbq('trackCustom', 'preferences', { platform: 'Beatport', });

		}

		if (iconLink.matches('[href*="play.google.com"]')) {

			fbq('trackCustom', 'preferences', { platform: 'Google Play', });

		}

		if (iconLink.matches('[href*="spotify.com"]')) {

			fbq('trackCustom', 'preferences', { platform: 'Spotify', });

		}

		if (iconLink.matches('[href*="deezer.com"]')) {

			fbq('trackCustom', 'preferences', { platform: 'Deezer', });

		}

		if (iconLink.matches('[href*="itun.es"]')) {

			fbq('trackCustom', 'preferences', { platform: 'Apple Music', });

		}

		if (iconLink.matches('[href*="youtube.com"]')) {

			fbq('trackCustom', 'preferences', { platform: 'YouTube', });

		}

	}

	document.addEventListener('click', clickEvent, false);

}