export default function navigation() {

	const html = document.querySelector('html');
	const body = document.querySelector('body');
	const header = document.querySelector('header');

	/* function navigate(e) {

		e.preventDefault();

		var clicked = e.target.closest('a');
		var url = clicked.href;

		if (html.classList.contains('overlay-visible')) {

			header.classList.remove('nav-open');
			header.classList.add('exiting');

			setTimeout(function() {

				html.classList.add('page-exiting');
				body.classList.add('page-exiting');

				setTimeout(function() {

					window.location = url;

				}, 150);

			}, 450);

		} else {

			html.classList.add('page-exiting');
			body.classList.add('page-exiting');

			setTimeout(function() {

				window.location = url;

			}, 250);

		}

	} */

	function clickEvent(e) {

		switch(true) {

			case e.target.matches('#nav-button'):
			case e.target.matches('#nav-button__hamburger'):
			case e.target.parentElement.matches('#nav-button'):
			case e.target.parentElement.matches('#nav-button__hamburger'):

				html.classList.toggle('overlay-visible');
				html.classList.toggle('nav-open');
				body.classList.toggle('overlay-visible');
				header.classList.toggle('nav-open');

			break;

			case e.target.matches('.overlay.modal-bg') && header.classList.contains('nav-open'):

				html.classList.remove('overlay-visible');
				html.classList.toggle('nav-open');
				body.classList.remove('overlay-visible');
				header.classList.remove('nav-open');

			break;

			// case e.target.matches('h1'):
			// case e.target.parentElement.matches('h1'):
			// case e.target.matches('h1 a'):
			// case e.target.matches('#primary-nav a'):
			// case e.target.matches('a.grid-link'):
			// case e.target.matches('.cell a'):
			// case e.target.matches('a.navigation'):
			//
			// 	navigate(e);
			//
			// break;

		}

	}

	document.addEventListener('click', clickEvent, false);

}
